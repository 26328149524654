import { useRuntimeConfig } from '#imports'
import * as Sentry from '@sentry/nuxt'

Sentry.init({
  // If set up, you can use your runtime config here
  dsn: useRuntimeConfig()?.public?.sentry?.dsn || 'https://f6d0c2456c8ff5e3fa1950688137556c@o4508089038733312.ingest.de.sentry.io/4508089089851472',
  integrations: [Sentry.replayIntegration()],
  // Tracing
  // We recommend adjusting this value in production, or using a tracesSampler for finer control.
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/soillink\.app\/api/, /^https:\/\/soillink\.dev\/api/, /^https:\/\/soil\.link\/api/],
  // Session Replay
  replaysSessionSampleRate: useRuntimeConfig()?.public?.sentry?.replaysSessionSampleRate || 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: useRuntimeConfig()?.public?.sentry?.replaysOnErrorSampleRate || 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: useRuntimeConfig()?.public?.environment || undefined,
  release: useRuntimeConfig()?.public?.release || undefined,
})
